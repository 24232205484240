<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pl-4 pr-4 pt-4 pb-2 gap-2 lg:mb-0">
      <div class="pb-4">
        <label for="nombre">Proveedores</label><br>
        <MultiSelect
          v-model="filters.proveedores"
          display="chip"
          placeholder="Seleccione un proveedor"
          :filter="true"
          @click="$h.xxsMultiSelectInput"
          panelClass="text-xxs"
          :options="proveedores"
          :filterFields="['CardCode', 'Proveedor']"
          mode="tags"
          class="multiselect-custom rounded-md border-gray-300 h-15 mt-2 p-multiselect-label p-inputtext-xs text-xs"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value text-sm" v-for="option of slotProps.value"
                 :key="option.CardCode">
              <div>{{ option.Proveedor }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              Selección de proveedores
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item text-sm">
              <div>
                {{ slotProps.option.Proveedor }}
              </div>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pb-4">
      <div class="pb-2 flex">
        <Button class="border-0" @click="searchFilters" label="Buscar"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" @click="clearFilters" label="Limpiar"/>
      </div>
    </div>
    <div>
      <div class="p-4">
        <DataTable
          :value="configProveedor"
          v-model:editingRows="editingRows"
          @row-edit-save="onRowEditSave"
          @row-edit-cancel="onRowEditCancel"
          class="p-datatable-sm text-xxs"
          showGridlines
          dataKey="id"
          editMode="row"
          responsiveLayout="scroll"
        >
          <template #empty>
            No hay datos para mostrar
          </template>
          <template #header v-if="validateConfig">
            <div class="flex justify-end align-items-center">
            <span class="p-input-icon-left">
              <Button v-tooltip.top="'Agregar proveedor'" icon="pi pi-plus" label="Agregar" @click="addProveedor"
                      class="p-button-outlined p-button-success p-button-sm "/>
            </span>
            </div>
          </template>
          <Column field="" header="Proveedor" headerStyle="justify-content: center;" style="min-width:23rem"
                  bodyStyle="justify-content: center;text-align: center" :class="displayAnimacion ? 'animacion-td' : ''">
            <template #body="{data}">
              <div>
                <div>
                  <p v-if="data.CardCode && data.CardName">{{ data.CardCode }} - {{ data.CardName }}</p>
                  <p v-else>Presione el botón de editar para seleccionar un proveedor</p>
                </div>
                <!--                <div class="flex-col gap-2">-->
                <!--                  <Badge value="Sin Guardar" v-if="!data.id" class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>-->
                <!--                </div>-->
              </div>
            </template>
            <template #editor="{data}">
              <Dropdown
                v-model="data.CardCode"
                class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
                @click="$h.xxsInput"
                panelClass="text-xxs"
                :options="proveedores"
                optionLabel="Proveedor"
                optionValue="CardCode"
                placeholder="Seleccione un proveedor..."
                :filter="true"
              />
            </template>
          </Column>
          <Column field="" header="Tiempo Respuesta" headerStyle="justify-content: center;" style="max-width:30rem"
                  bodyStyle="justify-content: center;text-align: center" :class="displayAnimacion ? 'animacion-td' : ''">
            <template #body="{data}">
              <div>
                <p v-if="!data.rangoHoras">Presione el botón de editar para ingresar un rango en horas</p>
                <p v-else>{{ data.rangoHoras }} Horas</p>
              </div>
            </template>
            <template #editor="{data}">
              <div>
                <InputNumber v-model="data.rangoHoras" class="p-inputtext-xs" inputId="minmax" showButtons
                             inputClass="p-inputtext-xxs w-12" buttonLayout="horizontal" :min="1" :max="336"
                             :readonly="true"/>
              </div>
            </template>
          </Column>
          <Column header="Acciones" :rowEditor="true" style="max-width:25rem" headerStyle="justify-content: center"
                  :class="displayAnimacion ? 'animacion-td' : ''"
                  bodyStyle="justify-content: center;text-align: center">
            <template #body="slotProps">
              <div class="flex gap-4 justify-center">
                <Button @click="editingRows = [slotProps.data]" icon="pi pi-pencil" v-tooltip.top="'Editar proveedor'"
                        class="p-button-rounded p-button-text p-button-plain"/>
                <Button icon="pi pi-trash" v-tooltip.top="'Eliminar proveedor'"
                        @click="deleteConfiguracion(slotProps)" class="p-button-rounded p-button-text p-button-plain"/>
                <!--                <Button icon="pi pi-save" v-tooltip.top="'Guardar configuración'"-->
                <!--                        @click="postConfiguracion(slotProps.data)" class="p-button-text p-button-success"/>-->
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
                   :rows="limit"
                   :totalRecords="totalRecords"
                   :rowsPerPageOptions="[1,10,20,30,50]"
                   :pageLinkSize="pageLinkSize"
                   @page="onPage($event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import Swal from 'sweetalert2'

export default {
  name: 'configuracion',
  setup () {
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const configProveedor = ref([])
    const displayAnimacion = ref(false)
    const proveedores = ref([])
    const editingRows = ref([])
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        proveedores: [...new Set(filters.proveedores.map((d) => d.CardCode))]
      }
    })
    const validateConfig = computed(() => {
      return !configProveedor.value.some((x) => !x.id)
    })
    const filters = reactive({
      proveedores: []
    })
    const clearFilters = () => {
      filters.proveedores = []
      getConfiguracion()
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getConfiguracion()
    }
    const addProveedor = () => {
      if (editingRows.value.length >= 1) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'No se puede agregar un nuevo proveedor mientras se esta editando un registro.',
          showConfirmButton: true,
          confirmButtonText: 'Ok'
        })
        return
      }
      const arrayT = configProveedor.value
      configProveedor.value = []
      configProveedor.value = [{
        id: null,
        CardCode: null,
        CardName: null,
        rangoHoras: 1
      }, ...arrayT]
      displayAnimacion.value = true
      setTimeout(() => {
        displayAnimacion.value = false
      }, 2000)
    }
    const searchFilters = () => {
      getConfiguracion()
    }
    const getProveedores = async () => {
      await _RecepcionPedidosService.value.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    // const postConfiguracion = (payload) => {
    //   _RecepcionPedidosService.value.postConfig(payload).then(({ data }) => {
    //     if (data.affected || data.id) {
    //       getConfiguracion()
    //     }
    //   })
    // }
    const getConfiguracion = async () => {
      await _RecepcionPedidosService.value.getConfig(params.value).then(({ data }) => {
        configProveedor.value = data.data
        totalRecords.value = data.rows[0].countRows
      })
      proveedores.value = proveedores.value.map((element) => {
        const find = configProveedor.value.find(({ CardCodeTemp }) => CardCodeTemp === element.CardCode)
        return find ? { ...element, RangoHoras: find.rangoHoras } : element
      })
    }
    const deleteConfiguracion = ({ data, index }) => {
      const find = proveedores.value.find((x) => x.CardCode === data.CardCode)
      let html = '¿Desea eliminar esta configuración para el proveedor?'
      if (find) html = `¿Desea eliminar esta configuración para el proveedor: <strong>${find.CardCode} - ${find.CardName}</strong>?`
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a',
        html: `${html}`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Si, eliminar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          if (data.id) {
            _RecepcionPedidosService.value.deleteConfig(data.id).then((response) => {
              if (response.data.affected) {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito',
                  text: 'Configuración eliminada correctamente.',
                  showConfirmButton: true,
                  confirmButtonText: 'Ok'
                }).then((resp) => {
                  if (resp.isConfirmed) {
                    getConfiguracion()
                  }
                })
              }
            })
          } else {
            configProveedor.value.splice(index, 1)
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Configuración eliminada correctamente.',
              showConfirmButton: true,
              confirmButtonText: 'Ok'
            })
          }
        }
      })
    }
    onMounted(async () => {
      await getProveedores()
      await getConfiguracion()
    })
    const onRowEditSave = ({ data, index }) => {
      const find = proveedores.value.find((x) => x.CardCode === data.CardCode)
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a',
        html:
          `¿Desea guardar esta configuracion para el proveedor: <strong>${find.CardCode} - ${find.CardName}</strong>?`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Si, guardar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          _RecepcionPedidosService.value.postConfig(data).then((response) => {
            if (response.data.affected || response.data.id) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                html:
                  `Proveedor: <strong>${find.CardCode} - ${find.CardName}</strong> actualizado correctamente`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Aceptar'
              }).then((result) => {
                if (result.isConfirmed) {
                  getConfiguracion()
                  configProveedor.value[index].CardName = find.CardName
                  configProveedor.value[index].rangoHoras = data.rangoHoras
                }
              })
            }
          })
        }
      })
    }
    const onRowEditCancel = ({ data, index }) => {
      const findProv = proveedores.value.find((a) => a.CardCode === data.CardCodeTemp)
      if (findProv) {
        configProveedor.value[index].Proveedor = `${findProv.CardCode} - ${findProv.CardName}`
        configProveedor.value[index].rangoHoras = findProv.RangoHoras
      }
    }
    return {
      proveedores,
      addProveedor,
      editingRows,
      onRowEditSave,
      getProveedores,
      configProveedor,
      // postConfiguracion,
      getConfiguracion,
      params,
      filters,
      onPage,
      totalRecords,
      pageLinkSize,
      limit,
      offset,
      page,
      searchFilters,
      clearFilters,
      deleteConfiguracion,
      onRowEditCancel,
      validateConfig,
      displayAnimacion
    }
  }
}

</script>
<style lang="scss" scoped>
::v-deep(.p-multiselect-label ) {
  display: flex !important;
  flex-wrap: wrap !important;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

::v-deep(.p-multiselect-label-container) {
  overflow-y: auto !important;
  max-height: 75px !important;
}

::v-deep(.country-item) {
  margin: 2px !important
}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717A;
  width: 100rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
</style>
<style scoped>

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

::v-deep(.p-inputnumber-buttons-horizontal) {
  justify-content: center;
}

::v-deep(.p-datatable-tbody) tr:nth-child(1) .animacion-td {
  animation-name: parpadeo;
  animation-duration: 1s; /* cambiamos la duración a 5 segundos */
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards; /* aseguramos que la animación se detenga en el estado final */
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s; /* cambiamos la duración a 5 segundos */
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 5;
  -webkit-animation-fill-mode: forwards; /* aseguramos que la animación se detenga en el estado final */
}

@-moz-keyframes parpadeo {
  0% {
     background-color: #ffffff;
  }
  50% {
     background-color: rgba(92, 115, 174, 0.99);
  }
  100% {
     background-color: #ffffff;
  }
}

@-webkit-keyframes parpadeo {
  0% {
     background-color: #ffffff;
  }
  50% {
     background-color: rgba(92, 115, 174, 0.99);
  }
  100% {
     background-color: #ffffff;
  }
}

@keyframes parpadeo {
  0% {
     background-color: #ffffff;
  }
  50% {
     background-color: rgba(92, 115, 174, 0.99);
  }
  100% {
     background-color: #ffffff;
  }
}
</style>
